<script setup lang="ts">
import type { SearchResultFragment } from '#graphql-operations'

const props = defineProps<{
  item?: SearchResultFragment
  freeShipping?: boolean
}>()

defineEmits<{
  (event: 'click'): void
}>()

const ratingScore = computed(() => getRating(props.item?.id, 1, [4]))
const priceFormatter = usePrice()

const discountPercentage = computed(() => {
  const { item } = props
  if (!item)
    return 0

  const currentPrice = 'min' in item.price ? item.price.min : item.price.value
  const originalPrice = item.customProductMappings?.priceBeforeDiscount

  if (originalPrice && currentPrice && originalPrice > currentPrice)
    return Math.round(((originalPrice - currentPrice) / originalPrice) * 100)

  return 0
})

function makeOldPrice(product?: SearchResultFragment) {
  if (!product?.price)
    return undefined

  const { price, customProductMappings } = product
  const priceBeforeDiscount = customProductMappings?.priceBeforeDiscount

  if (typeof priceBeforeDiscount !== 'number')
    return undefined

  if ('value' in price)
    return { value: priceBeforeDiscount }

  if ('min' in price && 'max' in price) {
    if (price.min !== price.max) {
      return {
        min: priceBeforeDiscount,
        max: Math.round(price.max * (priceBeforeDiscount / price.min)),
      }
    } else {
      return {
        min: priceBeforeDiscount,
        max: priceBeforeDiscount,
      }
    }
  }
}

const currentPrice = computed(() => {
  const { price } = props.item || {}
  return price ? 'value' in price ? price.value : price.min : undefined
})

const currentOldPrice = computed(() => {
  const { price } = props.item || {}
  const oldPrice = makeOldPrice(props.item)

  if (!oldPrice)
    return undefined

  const oldPriceValue = oldPrice ? 'value' in oldPrice ? oldPrice.value : oldPrice.min : undefined
  const priceValue = price ? ('value' in price) ? price.value : price.min : undefined

  if (!oldPriceValue || !priceValue || oldPriceValue <= priceValue)
    return undefined

  return oldPriceValue
})
</script>

<template>
  <NCard class="group relative overflow-hidden">
    <!-- Image wrapper -->
    <div class="relative h-auto w-full">
      <slot name="image">
        <NSkeleton v-if="!item || !item.featuredAsset?.preview" class="aspect-[9/10] h-full w-full flex-grow object-cover" wave shape="rect" />
        <NuxtImg
          v-else
          class="aspect-[9/10] flex-grow object-cover"
          :src="item.featuredAsset.preview" width="300" height="300" quality="40"
          :alt="item.name"
          loading="lazy"
        />
      </slot>

      <slot name="color">
        <!--        -->
      </slot>
      <slot name="badge">
        <div
          v-if="discountPercentage > 0"
          class="absolute left-2 top-2 rounded-md bg-primary-2 px-1.5 py-0.5 text-[0.8rem] text-white font-bold opacity-95 sm:text-xs"
        >
          - {{ discountPercentage }} %
        </div>
        <!--        -->
      </slot>
    </div>

    <div class="flex flex-1 flex-col px1 py1 sm:px4 sm:py4 xs:px2 xs:py2">
      <slot name="price">
        <NSkeleton v-if="!item || !item.price" />
        <div v-else class="flex space-x-1">
          <template v-if="currentOldPrice && currentPrice">
            <ins class="text-context font-medium decoration-none" n="slate9 dark:slate4">
              {{ priceFormatter(currentPrice, item.currency) }}
            </ins>
            <del class="text-context line-through" n="slate5">
              {{ priceFormatter(currentOldPrice, item.currency) }}
            </del>
          </template>
          <template v-else>
            <span class="text-context font-medium decoration-none" n="slate9 dark:slate4">
              {{ priceFormatter(currentPrice, item.currency) }}
            </span>
          </template>
        </div>
      </slot>

      <h3 class="line-clamp-2 overflow-hidden text-xs text-context sm:h10 sm:text-sm xs:text-sm" n=" slate5 dark:slate4">
        <NSkeleton v-if="!item || !item.id || !item.slug || !item.name" class="w-full" wave />
        <NuxtLinkLocale
          v-else
          :to="`/products/${item.id}/${item.slug}`"
          @click="$emit('click')"
        >
          <span aria-hidden="true" class="absolute inset-0" />
          {{ item.name }}
        </NuxtLinkLocale>
      </h3>
      <ContentGuard>
        <div class="flex flex-col items-start justify-between pt1 xs:flex-row xs:items-center">
          <template v-if="freeShipping || freeShipping != null">
            <NSkeleton v-if="!item || !item.price" class="w-3/4" />
            <span
              v-else
              class="text-[0.6rem] text-primary-3 sm:text-xs"
            >
              {{ $t('product.free_shipping.label').toUpperCase() }}
            </span>
          </template>

          <slot name="rating">
            <Rating :rating="ratingScore" class="-ml0.5" />
          </slot>
        </div>
      </ContentGuard>
    </div>
  </NCard>
</template>
